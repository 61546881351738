export function transformImage(settings) {
    let file = settings.file;
    let heightMaxSize = settings.heightMaxSize;
    let widthMaxSize = settings.widthMaxSize;
    let reader = new FileReader();
    let image = new Image();
    let canvas = document.createElement('canvas');

    let transform = function () {
        let width = image.width;
        let height = image.height;
        let isRotated = false;

        if (width > height) {
            width = image.height;
            height = image.width;
            isRotated = true;
        }

        //to resize!
        if (height > heightMaxSize) {
            width *= heightMaxSize / height;
            height = heightMaxSize;
        }

        if (width > widthMaxSize) {
            height *= widthMaxSize / width;
            width = widthMaxSize;
        }

        canvas.width = width;
        canvas.height = height;
        let ctx = canvas.getContext('2d');

        if (isRotated) {
            ctx.translate(width/2,height/2);
            ctx.rotate(90*Math.PI/180);
            ctx.drawImage(image,-height/2,-width/2, height, width);
        } else {
            ctx.drawImage(image, 0, 0, width, height);
        }

        return canvas.toDataURL('image/jpeg');
    };

    return new Promise(function (ok, no) {
        if (!file.type.match(/image.*/)) {
            no(new Error("Not an image"));
            return;
        }
        reader.onload = function (readerEvent) {
            image.onload = function () { return ok(transform()); };
            image.src = readerEvent.target.result;
        };
        reader.readAsDataURL(file);
    });
}