<template>
  <div>
    <b-row>
      <b-col>
        <canvas class="sig-canvas" width="320" height="160" ref="canvas" style="touch-action: none;">
          Veuillez changer de navigateur!
        </canvas>
      </b-col>
    </b-row>
    <b-row>
      <b-col style="text-align: right">
        <b-button variant="outline-secondary" @click="onClear">Effacer signature</b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>

export default {
  name: 'App',
  data() {
    return {
      form: {
        first_name: '',
        last_name: '',
      },
      canvas: null,
    }
  },

  mounted() {
    this.canvas = this.$refs.canvas;

    window.requestAnimFrame = (function(callback) {
      return window.requestAnimationFrame ||
          window.webkitRequestAnimationFrame ||
          window.mozRequestAnimationFrame ||
          window.oRequestAnimationFrame ||
          window.msRequestAnimaitonFrame ||
          function(callback) {
            window.setTimeout(callback, 1000 / 60);
          };
    })();

    var ctx = this.canvas.getContext("2d");
    ctx.strokeStyle = "#222222";
    ctx.lineWidth = 4;

    var drawing = false;
    var mousePos = {
      x: 0,
      y: 0
    };
    var lastPos = mousePos;

    this.canvas.addEventListener("mousedown", (e) => {
      drawing = true;
      lastPos = getMousePos(this.canvas, e);
    }, false);

    this.canvas.addEventListener("mouseup", (e) => {
      drawing = false;
    }, false);

    this.canvas.addEventListener("mousemove", (e) => {
      mousePos = getMousePos(this.canvas, e);
    }, false);

    // Add touch event support for mobile
    this.canvas.addEventListener("touchstart", (e) => {

    }, false);

    this.canvas.addEventListener("touchmove", (e) => {
      var touch = e.touches[0];
      var me = new MouseEvent("mousemove", {
        clientX: touch.clientX,
        clientY: touch.clientY
      });
      this.canvas.dispatchEvent(me);
    }, false);

    this.canvas.addEventListener("touchstart", (e) => {
      mousePos = getTouchPos(this.canvas, e);
      var touch = e.touches[0];
      var me = new MouseEvent("mousedown", {
        clientX: touch.clientX,
        clientY: touch.clientY
      });
      this.canvas.dispatchEvent(me);
    }, false);

    this.canvas.addEventListener("touchend", (e) => {
      var me = new MouseEvent("mouseup", {});
      this.canvas.dispatchEvent(me);
    }, false);

    function getMousePos(canvasDom, mouseEvent) {
      var rect = canvasDom.getBoundingClientRect();
      return {
        x: mouseEvent.clientX - rect.left,
        y: mouseEvent.clientY - rect.top
      }
    }

    function getTouchPos(canvasDom, touchEvent) {
      var rect = canvasDom.getBoundingClientRect();
      return {
        x: touchEvent.touches[0].clientX - rect.left,
        y: touchEvent.touches[0].clientY - rect.top
      }
    }

    function renderCanvas() {
      if (drawing) {
        ctx.moveTo(lastPos.x, lastPos.y);
        ctx.lineTo(mousePos.x, mousePos.y);
        ctx.stroke();
        lastPos = mousePos;
      }
    }

    // Prevent scrolling when touching the canvas
    document.body.addEventListener("touchstart", (e) => {
      if (e.target == this.canvas) {
        e.preventDefault();
      }
    }, false);
    document.body.addEventListener("touchend", (e) => {
      if (e.target == this.canvas) {
        e.preventDefault();
      }
    }, false);
    document.body.addEventListener("touchmove", (e) => {
      if (e.target == this.canvas) {
        e.preventDefault();
      }
    }, false);

    (function drawLoop() {
      requestAnimFrame(drawLoop);
      renderCanvas();
    })();
  },

  methods: {
    onClear(event) {
      event.preventDefault();
      this.clear();
    },
    clear() {
      this.canvas.width = this.canvas.width;
    },
    toDataURL() {
      return this.canvas.toDataURL();
    }
  }
}
</script>

<style lang="less">

.sig-canvas {
  border: 2px dotted #CCCCCC;
  border-radius: 15px;
  cursor: crosshair;
}

</style>
