<template>
  <b-container>
    Nb interventions non envoyées : {{interventions.length}}
    <b-button variant="primary" @click="onOffload">Offload</b-button>
    <br>

    <b-alert v-model="showSuccess" variant="success" dismissible>
      Intervention encodée avec succès!
    </b-alert>

    <b-alert v-model="showSuccessLocal" variant="warning" dismissible>
      Intervention encodée avec succès mais sauvegardée localement!
    </b-alert>

    <b-alert v-model="showSuccessOffload" variant="success" dismissible>
      Interventions offloadés avec succès!
    </b-alert>

    <b-alert v-model="showFailedOffload" variant="danger" dismissible>
      {{countFailedDeletedItems}} interventions n'ont pas été offloadé !
    </b-alert>

    <b-form @submit.prevent="onSubmit" @reset="onReset" style="margin-bottom: 20px;">

      <h2>Utilisateur : </h2>

      <b-form-group label="Nom:" label-for="f-user-name">
        <b-form-input
            id="f-user-name"
            v-model="form.user_name"
        ></b-form-input>
      </b-form-group>

      <b-form-group label="Rue et N°:" label-for="f-user-street">
        <b-form-input
            id="f-user-street"
            v-model="form.user_street"
        ></b-form-input>
      </b-form-group>

      <b-form-group label="Code Postal:" label-for="f-user-zip-code">
        <b-form-input
            id="f-user-zip-code"
            v-model="form.user_zip_code"
        ></b-form-input>
      </b-form-group>

	    <b-form-group label="Ville:" label-for="f-user-city">
		    <b-form-input
				    id="f-user-city"
				    v-model="form.user_city"
		    ></b-form-input>
	    </b-form-group>

      <b-form-group label="Etage:" label-for="f-user-floor">
        <b-form-input
            id="f-user-floor"
            v-model="form.user_floor"
        ></b-form-input>
      </b-form-group>

      <b-form-group label="Tel:" label-for="f-user-phone">
        <b-form-input
            id="f-user-phone"
            v-model="form.user_phone"
        ></b-form-input>
      </b-form-group>

      <b-form-group label="Email:" label-for="f-user-email">
        <b-form-input
            id="f-user-email"
            type="email"
            v-model="form.user_email"
        ></b-form-input>
      </b-form-group>

      <h2>Propriétaire : </h2>

      <b-form-group label="Nom:" label-for="f-owner-name">
        <b-form-input
            id="f-owner-name"
            v-model="form.owner_name"
        ></b-form-input>
      </b-form-group>

      <b-form-group label="Rue et N°:" label-for="f-owner-street">
        <b-form-input
            id="f-owner-street"
            v-model="form.owner_street"
        ></b-form-input>
      </b-form-group>

      <b-form-group label="Code Postal:" label-for="f-owner-zip-code">
        <b-form-input
            id="f-owner-zip-code"
            v-model="form.owner_zip_code"
        ></b-form-input>
      </b-form-group>

	    <b-form-group label="Ville:" label-for="f-owner-city">
		    <b-form-input
				    id="f-owner-city"
				    v-model="form.owner_city"
		    ></b-form-input>
	    </b-form-group>

      <b-form-group label="Etage:" label-for="f-owner-floor">
        <b-form-input
            id="f-owner-floor"
            v-model="form.owner_floor"
        ></b-form-input>
      </b-form-group>

      <b-form-group label="Tel:" label-for="f-owner-phone">
        <b-form-input
            id="f-owner-phone"
            v-model="form.owner_phone"
        ></b-form-input>
      </b-form-group>

      <b-form-group label="Email:" label-for="f-owner-email">
        <b-form-input
            id="f-owner-email"
            type="email"
            v-model="form.owner_email"
        ></b-form-input>
      </b-form-group>

      <h2>Contenu : </h2>

      <b-form-group label="Description:" label-for="f-description">
        <b-form-textarea
            id="f-description"
            v-model="form.description"
            rows="6"
        ></b-form-textarea>
      </b-form-group>

      <b-form-group label="Remarques:" label-for="f-remarks">
        <b-form-textarea
            id="f-remarks"
            v-model="form.remarks"
            rows="6"
        ></b-form-textarea>
      </b-form-group>

      <b-form-group label="Pièces et MO:" label-for="f-labor">
        <b-form-textarea
            id="f-labor"
            v-model="form.labor"
            rows="6"
        ></b-form-textarea>
      </b-form-group>

      <b-form-group label="Réception du travail effectué:" label-for="f-is-delivered">
        <b-form-radio v-model="form.is_delivered" :value="true">OUI</b-form-radio>
        <b-form-radio v-model="form.is_delivered" :value="false">NON</b-form-radio>
      </b-form-group>

      <b-form-group label="Habitation de plus de 10 ans:" label-for="f-is-10-years-old">
        <b-form-radio v-model="form.is_10_years_old" :value="true">OUI</b-form-radio>
        <b-form-radio v-model="form.is_10_years_old" :value="false">NON</b-form-radio>
      </b-form-group>

      <b-form-group label="Prix:" label-for="f-price">
        <b-form-input
            id="f-price"
            v-model="form.price"
        ></b-form-input>
      </b-form-group>

      <b-form-group label="Mode de paiement:" label-for="f-payment-method">
        <b-form-radio v-model="form.payment_method" value="A facturer">A facturer</b-form-radio>
        <b-form-radio v-model="form.payment_method" value="Cash">Cash</b-form-radio>
        <b-form-radio v-model="form.payment_method" value="Bancontact">Bancontact</b-form-radio>
      </b-form-group>

      <b-form-group label="Heure d'arrivée:" label-for="f-arriving-time">
        <b-input-group class="mb-3">
          <b-form-input
              id="f-arriving-time"
              v-model="form.arriving_time"
              type="text"
              placeholder="HH:mm"
          ></b-form-input>
          <b-input-group-append>
            <b-form-timepicker
                v-model="form.arriving_time"
                button-only
                right
                locale="fr"
            ></b-form-timepicker>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>

      <b-form-group label="Heure de sortie:" label-for="f-exit-time">
        <b-input-group class="mb-3">
          <b-form-input
              id="f-exit-time"
              v-model="form.exit_time"
              type="text"
              placeholder="HH:mm"
          ></b-form-input>
          <b-input-group-append>
            <b-form-timepicker
                v-model="form.exit_time"
                button-only
                right
                locale="fr"
            ></b-form-timepicker>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>

      <b-form-group label="Technicien:" label-for="f-technician">
        <b-form-select id="f-technician" v-model="form.technician" :options="technicians"></b-form-select>
      </b-form-group>


      <b-form-group label="Photos:" label-for="f-pictures">
        <b-form-file
            id="f-pictures"
            v-model="files"
            placeholder="Choisir une/des image(s)"
            multiple
            accept="image/jpeg, image/png"
        ></b-form-file>
      </b-form-group>

      <Esignature ref="signature"></Esignature>

      <b-button type="submit" variant="primary" style="margin-right: 20px;" :disabled="submitDisabled">Envoyer</b-button>
<!--      <b-button type="reset" variant="danger">Reset</b-button>-->
    </b-form>
  </b-container>
</template>

<script>

import Esignature from "@/components/Esignature";
import moment from 'moment';
import {transformImage} from './utilities/imageTransformer';

export default {
  name: 'App',
  data() {
    return {
      form: {
        user_name: '',
        user_street: '',
        user_zip_code: '',
        user_city: '',
        user_floor: '',
        user_phone: '',
        user_email: '',
        owner_name: '',
        owner_street: '',
        owner_zip_code: '',
        owner_city: '',
        owner_floor: '',
        owner_phone: '',
        owner_email: '',
        description: '',
        remarks: '',
        labor: '',
        is_delivered: true,
        is_10_years_old: true,
        price: '',
        payment_method: 'A facturer',
        arriving_time: '',
        exit_time: '',
        technician: 'kevin_de_castro',
        created_date: '',
      },
      db: null,
      interventions: [],
      showSuccess: false,
      showSuccessLocal: false,
      showSuccessOffload: false,
      showFailedOffload: false,
      countFailedDeletedItems: 0,
      submitDisabled: false,
      files: [],
      transformedFiles: [],
      technicians: [
        { value: 'kevin_de_castro', text: 'Kevin De Castro' },
      ],
    }
  },
  mounted() {
    this.fetchLocalInterventions();
  },
  methods: {
    fetchLocalInterventions() {
      this.getDb()
        .then((db) => {
          return new Promise(resolve => {
            let trans = db.transaction(['interventions'],'readonly');
            trans.oncomplete = () => {
              resolve(interventions);
            };

            let store = trans.objectStore('interventions');
            let interventions = [];

            store.openCursor().onsuccess = e => {
              let cursor = e.target.result;
              if (cursor) {
                interventions.push(cursor.value)
                cursor.continue();
              }
            };
          })
          .then((interventions) => {
            this.interventions = [...interventions];
          });
        });
    },
    onSubmit(event) {
      event.preventDefault();
      let signatureUrl = this.$refs.signature.toDataURL();
      this.form.created_date = moment().format('DD/MM/YYYY');

      this.submitDisabled = true;
      this.makeRequest({
        ...this.form,
        'signature_data_url': signatureUrl,
        'pictures': this.transformedFiles,
      })
      .then(() => {
        this.reset();
        this.showSuccess = true;
        window.scrollTo({ top: 0, behavior: 'smooth' });
      })
      .catch(() => {
        this.saveLocally();
        this.fetchLocalInterventions();
      })
      .finally(() => {
        this.submitDisabled = false;
      })
    },
    saveLocally() {
      this.getDb()
        .then((db) => {
          return new Promise(resolve => {
            let signatureUrl = this.$refs.signature.toDataURL();

            let transaction = db.transaction(['interventions'],'readwrite');
            transaction.oncomplete = () => {
              resolve();
            };

            let store = transaction.objectStore('interventions');
            store.put( {...this.form, 'signature_data_url': signatureUrl, 'pictures': [...this.transformedFiles]});
          });
        })
        .then(() => {
          this.reset();
          this.showSuccessLocal = true;
          window.scrollTo({ top: 0, behavior: 'smooth' });
        });
    },
    onReset(event) {
      event.preventDefault();
      this.reset();
    },
    reset() {
      // Reset our form values
      this.form.user_name = '';
      this.form.user_street = '';
      this.form.user_zip_code = '';
      this.form.user_city = '';
      this.form.user_floor = '';
      this.form.user_phone = '';
      this.form.user_email = '';
      this.form.owner_name = '';
      this.form.owner_street = '';
      this.form.owner_zip_code = '';
      this.form.owner_city = '';
      this.form.owner_floor = '';
      this.form.owner_phone = '';
      this.form.owner_email = '';
      this.form.description = '';
      this.form.remarks = '';
      this.form.labor = '';
      this.form.is_delivered = true;
      this.form.is_10_years_old = true;
      this.form.price = '';
      this.form.payment_method = 'A facturer';
      this.form.arriving_time = '';
      this.form.exit_time = '';
      this.form.technician = 'kevin_de_castro';
      this.files = [];
      this.$refs.signature.clear();
    },
    onOffload(event) {
      event.preventDefault();

      let promises = this.interventions.map(intervention => {
        return new Promise(resolve => {
          this.makeRequest({...intervention})
          .then(() => {
            resolve({'success': true, 'intervention': intervention});
          })
          .catch(() => {
            resolve({'success': false, 'intervention': intervention});
          })
        });
      });

      Promise.all(promises)
        .then(items => {
          items.filter(item => {
            return !!item.success;
          })
          .forEach(item => {
            this.deleteIntervention(item.intervention.id);
          });

          this.countFailedDeletedItems = items.filter(item => {
            return !item.success;
          }).length;

          this.showFailedOffload = this.countFailedDeletedItems > 0;
          this.showSuccessOffload = !this.showFailedOffload;

          this.fetchLocalInterventions();
        });
    },
    deleteIntervention(id) {
      this.getDb()
        .then((db) => {
          return new Promise(resolve => {
            let transaction = db.transaction(['interventions'],'readwrite');
            transaction.oncomplete = () => {
              resolve();
            };

            let store = transaction.objectStore('interventions');
            store.delete(id);
          });
        });
    },
    makeRequest(data) {
      return this.axios.post('https://castro-api.mc-soft.be/api/interventions', data)
      // return this.axios.post('http://127.0.0.1:8000/api/interventions', data)
    },
    getDb() {
      return new Promise((resolve, reject) => {
        if(this.db) { return resolve(this.db); }

        const DB_NAME = 'castro-db';
        const DB_VERSION = 1;

        let request = window.indexedDB.open(DB_NAME, DB_VERSION);

        request.onerror = e => {
          console.log('Error opening db', e);
          reject('Error');
        };

        request.onsuccess = e => {
          this.db = e.target.result;
          resolve(this.db);
        };

        request.onupgradeneeded = e => {
          console.log('onupgradeneeded');
          let db = e.target.result;
          db.createObjectStore("interventions", { autoIncrement: true, keyPath:'id' });
        };
      });
    },
  },
  watch: {
    files() {
      let promises = this.files.map(file => {
        return transformImage({file: file, heightMaxSize: 505, widthMaxSize: 345});
      });

      Promise
        .all(promises)
        .then(files => {
          this.transformedFiles = files;
        });
    }
  },
  components: {
    Esignature
  }
}
</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
